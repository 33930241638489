import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useParams } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import axios from "axios";
import tick from "../Assets/Group.png";
import Navbar from "../Components/Navbar";
import icon from "../Assets/image 89.png";
import Footer from "../Components/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import i1 from "../Assets/why.png";
import i2 from "../Assets/div.featureCard__image.png";
import ig from "../Assets/journey2.png.png";
import whats from "../Assets/book1 (1).webp";
import whats2 from "../Assets/book1 (2).webp";
import whats3 from "../Assets/whatsapp-icon.svg";
import veg from "../Assets/veg (1).png";
import veg2 from "../Assets/veg (2).png";
import imga from "../Assets/images-removebg-preview.png";
import Loader from "../Components/Loader";
import size from "../Assets/Screenshot_2024-11-28_123540-removebg-preview.png";
import size1 from "../Assets/person.png";
import size2 from "../Assets/room.png";
import Ticket from "../Components/Ticket";

function CollectionDetail() {
  const { id: hotelId } = useParams();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [ticketModel, setTicketModel] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [selectedMod, setSelectedMod] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [noOfPeople, setNoOfPeople] = useState(null);
  const [customerType, setCustomerType] = useState("");
  const [isSupported, setIsSupported] = useState(false);
  const [hotel, setHotel] = useState(null);
  const [groupedAmenities, setGroupedAmenities] = useState({});
  // const [connectionNumber, setConnectionNumber]=useState("")

  const customerTypeOptions = [
    { value: "bachelors", label: "Bachelors (boys group)" },
    { value: "girlsGroup", label: "Girls group" },
    { value: "couples", label: "Couples" },
    { value: "corporate", label: "Corporate" },
    { value: "family", label: "Family" }
  ];

  useEffect(() => {
    const userData =
      (localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user"))) ||
      "";
    // console.log(userData)
    if (userData) {
      setName(userData?.name);
      setContact(userData?.phone);
      setEmail(userData?.email);
    }
  }, [hotelId]);

  const handleToogleModel = (val, mod) => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Token not found. Please login.");
      return;
    }

    const hotelData = JSON.parse(localStorage.getItem("hotelData")) || {};
    const currentTime = new Date().getTime();
    const twelveHoursInMillis = 12 * 60 * 60 * 1000;

    if (
      hotelData[hotelId] &&
      currentTime - hotelData[hotelId].timestamp < twelveHoursInMillis
    ) {
      // If the hotelId exists and is within the 12-hour window, redirect directly
      redirectTo(mod);
      return;
    }
    setModal(val);
    setSelectedMod(mod);
  };

  // Get hotelId from the URL params

  // State for storing hotel details

  // console.log(hotel?.amenities)

  // Fetch hotel details on mount
  useEffect(() => {
    const fetchHotelDetails = async () => {
      try {
        const response = await axios.get(
          `https://tripolo-backend.vercel.app/api/hotel/single/${hotelId}`
          // `http://localhost:8080/api/hotel/single/${hotelId}`
        );
        setHotel(response.data.data);
      } catch (error) {
        console.error("Error fetching hotel details:", error);
      }
      finally {
        setLoading(false); // Stop loading
      }
    };

    if (hotelId) {
      fetchHotelDetails();
    }
  }, [hotelId]);

  const filteredOptions =
    customerTypeOptions &&
    hotel &&
    customerTypeOptions.filter((option) =>
      hotel.customerType.includes(option.value)
    );

  // Date change handlers
  const handleStartDateChange = (date) => setStartDate(date);
  const handleEndDateChange = (date) => setEndDate(date);

  // Share functionality
  useEffect(() => {
    if (navigator.share) {
      setIsSupported(true);
    }
  }, []);

  const handleShare = async () => {
    try {
      await navigator.share({
        title: "Check out this cool app!",
        text: "This is an amazing app you should try!",
        url: window.location.href
      });
      console.log("Share was successful.");
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Token not found. Please login.");
      return;
    }

    const allowedValues = filteredOptions.map((option) => option.value);

    if (!allowedValues.includes(customerType)) {
      alert(
        "The selected type of customer is not accepted in this property, kindly go through other properties."
      );
      return;
    }

    const hotelData = JSON.parse(localStorage.getItem("hotelData")) || {};
    const currentTime = new Date().getTime();
    const twelveHoursInMillis = 12 * 60 * 60 * 1000;

    if (
      hotelData[hotelId] &&
      currentTime - hotelData[hotelId].timestamp < twelveHoursInMillis
    ) {
      // If the hotelId exists and is within the 12-hour window, redirect directly
      redirectTo(selectedMod);
      return;
    }

    if (!name || !email || !contact || !startDate || !endDate || !noOfPeople) {
      alert("All fields are required!");
      return;
    }

    const formattedStartDate = startDate ? new Date(startDate) : null;
    const formattedEndDate = endDate ? new Date(endDate) : null;

    try {
      const response = await axios.post(
        "https://tripolo-backend.vercel.app/api/lead/create",
        {
          name,
          mobile: contact,
          email,
          checkInDate: formattedStartDate,
          checkOutDate: formattedEndDate,
          noOfPeople, // Pass noOfPeople in the API request
          customerType,
          hotelId // Use the hotelId from URL params
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.status == 200) {
        localStorage.setItem(
          "hotelData",
          JSON.stringify({
            ...hotelData,
            [hotelId]: { timestamp: currentTime }
          })
        );
        console.log("Booking successful:", response.data);
        setModal(false); // Close modal on success
        redirectTo(selectedMod);
      }

      // Show success alert
      // alert(
      //   "Thank you for your booking! Your reservation has been successfully placed."
      // );
    } catch (error) {
      console.error("Error during booking:", error);
      alert(
        error.response ? error.response.data.message : "An error occurred."
      );
    }
  };

  const redirectTo = (mod) => {
    if (mod === "call") {
      // Open phone call in a new tab (using tel protocol)
      window.open(`tel:${hotel?.customerConnect}`, "_blank");
    } else if (mod === "whatsapp") {
      // Open WhatsApp in a new tab
      const whatsappURL = `https://wa.me/${hotel?.whatsappConnectNumber}?text=Hello! I am interested in your services.`;
      window.open(whatsappURL, "_blank");
    } else {
      alert("Invalid action selected.");
    }
  };

  useEffect(() => {
    if (hotel && hotel.amenities && hotel.amenities.length > 0) {
      // Group amenities by category
      const grouped = hotel.amenities.reduce((acc, amenity) => {
        if (!acc[amenity.category.categoryName]) {
          acc[amenity.category.categoryName] = [];
        }
        acc[amenity.category.categoryName].push(amenity);
        return acc;
      }, {});
      const sortedCategoryNames = Object.keys(grouped).sort();
      setGroupedAmenities(grouped);
    }
  }, [hotel]);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  if (loading) {
    return <Loader />; // Display loader while fetching data
  }

  return (
    <>
      <Navbar />

      <div className="path">
        <p>
          <Link style={{ color: "black", textDecoration: "none" }} to="/">
            {" "}
            Home
          </Link>

          <i class="bi bi-chevron-right"></i>

          <span>{hotel.propertyName}</span>
        </p>
      </div>
      <div className="detail-slid">
        <div className="share2">
          {isSupported ? (
            <i onClick={handleShare} class="bi bi-share-fill"></i>
          ) : (
            <p></p>
          )}
        </div>
        <>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false
            }}
            pagination={{
              clickable: true
            }}
            // navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper2">
            <button className="all-img-btn " onClick={() => setModal3(true)}>
              {" "}
              View Photos
            </button>

            <div className="youtube-video">
              <a target="blank" href={hotel.youtubeVideoLink}>
                <i class="bi bi-play-circle-fill"></i>
              </a>
            </div>

            {hotel.images.other.map((image, index) => (
              <SwiperSlide key={index} className="newslid">
                <img src={image} alt={`Slide ${index + 1}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      </div>

      <div className="main-des-img">
        <div className="main-des-img-right">
          <img src={hotel.images.profile} alt={hotel.propertyName} />
          <div className="share">
            {isSupported ? (
              <i onClick={handleShare} class="bi bi-share-fill"></i>
            ) : (
              <p></p>
            )}
          </div>
          <button className="all-img-btn" onClick={() => setModal3(true)}>
            {" "}
            View Photos
          </button>
          <div className="youtube-video">
            <a target="blank" href={hotel.youtubeVideoLink}>
              <i class="bi bi-play-circle-fill"></i>
            </a>
          </div>
        </div>
        <div className="main-des-img-left">
          {hotel.images.other.slice(0, 2).map((image, index) => (
            <img key={index} src={image} alt={`Hotel image ${index + 1}`} />
          ))}
        </div>
      </div>

      <div className="tab-st">
        <div className="tab-left-side">
          <div className="tab-container">
            <div className="tabs">
              <button
                className="tab-btn"
                onClick={() =>
                  document
                    .getElementById("overview-section")
                    .scrollIntoView({ behavior: "smooth" })
                }>
                Description
              </button>
              <button
                className="tab-btn"
                onClick={() =>
                  document
                    .getElementById("highiglts-sm")
                    .scrollIntoView({ behavior: "smooth" })
                }>
                Highlights
              </button>
              <button
                className="tab-btn"
                onClick={() =>
                  document
                    .getElementById("Pricing-sm")
                    .scrollIntoView({ behavior: "smooth" })
                }>
                Pricing
              </button>
              <button
                className="tab-btn"
                onClick={() =>
                  document
                    .getElementById("Amenities-sm")
                    .scrollIntoView({ behavior: "smooth" })
                }>
                Amenities
              </button>
              <button
                className="tab-btn"
                onClick={() =>
                  document
                    .getElementById("Spaces-sm")
                    .scrollIntoView({ behavior: "smooth" })
                }>
                Spaces
              </button>

              <button
                className="tab-btn"
                onClick={() =>
                  document
                    .getElementById("Meals-sm")
                    .scrollIntoView({ behavior: "smooth" })
                }>
                Meals
              </button>
              <button
                className="tab-btn"
                onClick={() =>
                  document
                    .getElementById("Location-sm")
                    .scrollIntoView({ behavior: "smooth" })
                }>
                Location
              </button>

              <button
                className="tab-btn"
                onClick={() =>
                  document
                    .getElementById("T-sm")
                    .scrollIntoView({ behavior: "smooth" })
                }>
                T&C
              </button>
            </div>

            <br />

            <div className="tab-content">
              {activeTab === 1 && (
                <>
                  <div className="description">
                    <h2 id="overview-section">Description</h2>

                    <p>
                      All our Deluxe rooms have big windows to help you take a
                      broad view of the cityscape and nature. We offer bigger
                      bed and every bathroom has bathtub and shower, which
                      brings relaxation to you after a long day. Fast WIFI
                      connection, satelite TV and international standard
                      electric socket are standard throughout Hotel. Lorem ipsum
                      dolor sit amet, consectetur adipisicing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum. Sed ut
                      perspiciatis unde omnis iste natus error sit
                    </p>

                    <hr id="highiglts-sm" />

                    <h2>Highlight</h2>

                    <ul>
                      <li>
                        <div
                          className="highLight"
                          dangerouslySetInnerHTML={{
                            __html: hotel.highLight || ""
                          }}
                        />
                      </li>
                    </ul>
                    <hr id="Pricing-sm" />

                    <h2>Pricing</h2>
                    <div className="shad">
                      <table class="no-lines-table">
                        <tr className="type-button-div">
                          <td className="type-button">
                            <b>Type</b>
                          </td>
                          <td className="type-button">
                            <b>Weekdays</b>
                          </td>

                          <td className="type-button">
                            <b>Weekend</b>
                          </td>
                        </tr>
                        {hotel.rates &&
                          hotel.rates.map((rates, index) => (
                            <tr key={index}>
                              <td>{rates.type}</td>
                              <td>{rates.weekday}</td>
                              <td>{rates.weekend}</td>
                            </tr>
                          ))}
                      </table>
                    </div>
                    <hr id="Amenities-sm" />

                    <h2>Ameneties</h2>

                    <div className="Ameneties-div">
                      {hotel.amenities &&
                        hotel.amenities.slice(0, 5).map((amenity, index) => (
                          <div key={index} className="Ameneties">
                            <h3>
                              <img
                                src={amenity.image}
                                alt={amenity.amenityName}
                              />
                              {amenity.amenityName}
                            </h3>
                          </div>
                        ))}
                      <button onClick={() => setModal2(true)}>See More</button>
                    </div>
                    <hr id="Spaces-sm" />

                    <h2>Spaces</h2>

                    <div className="Spaces">
                      {hotel.rooms &&
                        hotel.rooms.map((rooms, index) => (
                          <div key={index}>
                            <div
                              className="Spaces-card"
                              style={{
                                backgroundImage: `url(${rooms.roomImages})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center"
                              }}>
                              <div className="space-over">
                                <h3>{rooms.roomName}</h3>
                              </div>
                            </div>

                            <ul>
                              <li>
                                <div
                                  className="space-di"
                                  dangerouslySetInnerHTML={{
                                    __html: rooms.description || ""
                                  }}
                                />
                              </li>
                              {/* <li >{rooms.description}</li> */}
                            </ul>
                          </div>
                        ))}
                    </div>

                    {/* <div className="Spaces">
<div>

<div className="Spaces-card">
<h3>Room 1</h3>


</div>
<br/>
<ul>
  <li>This is an air-conditioned room on the first floor.</li>
  <li>The room offers a king-sized bed, WiFi access and an attached balcony with a pool view.</li>
  <li>It has an attached bathroom equipped with a geyser, towels, basic toiletries and a separate dressing area.</li>


</ul>

</div>
<div>

<div className="Spaces-card">
<h3>Room 2</h3>

</div>
<br/>
<ul>
  <li>This is an air-conditioned room on the first floor.</li>
  <li>The room offers a king-sized bed, WiFi access and an attached balcony with a pool view.</li>
  <li>It has an attached bathroom equipped with a geyser, towels, basic toiletries and a separate dressing area.</li>


</ul>
</div>
<div>

<div className="Spaces-card">
<h3>Room 3</h3>

</div>
<br/>
<ul>
  <li>This is an air-conditioned room on the first floor.</li>
  <li>The room offers a king-sized bed, WiFi access and an attached balcony with a pool view.</li>
  <li>It has an attached bathroom equipped with a geyser, towels, basic toiletries and a separate dressing area.</li>


</ul>
</div>

</div> */}

                    <hr id="Meals-sm" />

                    <h2>Meals</h2>

                    <div className="veg">
                      <a href={hotel.meals.vegPdf} target="blank">
                        <button>
                          <img src={veg} />
                          Veg
                        </button>
                      </a>
                      <a href={hotel.meals.nonVegPdf} target="blank">
                        <button>
                          <img src={veg2} />
                          Mix Menu
                        </button>
                      </a>
                    </div>

                    <hr id="Location-sm" />

                    <h2>Location</h2>

                    <div className="map">
                      <div
                        className="map"
                        dangerouslySetInnerHTML={{
                          __html: hotel.mapUrl || ""
                        }}
                      />
                    </div>

                    <hr id="T-sm" />

                    <h2>Terms and Conditions</h2>

                    <ul>
                      <li>
                        <div
                          className="propertyPolicies"
                          dangerouslySetInnerHTML={{
                            __html: hotel.propertyPolicies || ""
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                </>
              )}
              {activeTab === 2 && (
                <>
                  <div className="description">
                    <h2>Highlight</h2>

                    <br />

                    <ul>
                      <li>
                        <img src={tick} />
                        {hotel.highLight}
                      </li>
                    </ul>
                  </div>
                </>
              )}

              {activeTab === 3 && (
                <>
                  <div className="description">
                    <h2>Spaces</h2>
                    <br />
                    <div className="Spaces">
                      {hotel.rooms &&
                        hotel.rooms.map((rooms, index) => (
                          <div key={index}>
                            <div
                              className="Spaces-card"
                              style={{
                                backgroundImage: `url(${rooms.roomImages})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center"
                              }}>
                              <h3>{rooms.roomName}</h3>
                            </div>
                            <br />
                            <ul>
                              <li>{rooms.description}</li>
                            </ul>
                          </div>
                        ))}
                    </div>
                    <br />

                    <br />
                  </div>
                </>
              )}
              {activeTab === 4 && (
                <>
                  <div className="description">
                    <br />
                    <h2>Ameneties</h2>
                    <br />
                    <div className="Ameneties-div">
                      {hotel.amenities &&
                        hotel.amenities.map((amenities, index) => (
                          <div key={index} className="Ameneties">
                            <h3>
                              <img src={amenities.image} />
                              {amenities.amenityName}
                            </h3>
                          </div>
                        ))}
                    </div>
                    <br />

                    <br />

                    <br />
                  </div>
                </>
              )}
              {activeTab === 5 && (
                <>
                  <div className="description">
                    <br />
                    <h2>Meals</h2>
                    <br />
                    <div className="veg">
                      <a href={hotel.meals.vegPdf} target="blank">
                        <button>
                          <img src={veg} />
                          Veg
                        </button>
                      </a>
                      <a href={hotel.meals.nonVegPdf} target="blank">
                        <button>
                          <img src={veg2} />
                          Mix Menu
                        </button>
                      </a>
                    </div>
                  </div>
                </>
              )}
              {activeTab === 6 && (
                <>
                  <div className="description">
                    <br />

                    <h2>Location</h2>
                    <br />

                    <div className="map">{hotel.mapUrl}</div>
                    <br />
                  </div>
                </>
              )}
              {activeTab === 7 && (
                <>
                  <div className="description">
                    <br />

                    <h2>Pricing</h2>
                    <div className="shad">
                      <table class="no-lines-table">
                        <tr className="type-button-div">
                          <td className="type-button">
                            <b>Type</b>
                          </td>
                          <td className="type-button">
                            <b>Weekdays</b>
                          </td>

                          <td className="type-button">
                            <b>Weekend</b>
                          </td>
                        </tr>
                        {hotel.rates &&
                          hotel.rates.map((rates, index) => (
                            <tr key={index}>
                              <td>{rates.type}</td>
                              <td>{rates.weekday}</td>
                              <td>{rates.weekend}</td>
                            </tr>
                          ))}
                      </table>
                    </div>
                  </div>
                </>
              )}
              {activeTab === 8 && (
                <>
                  <div className="description">
                    <br />

                    <h2>Terms and Conditions</h2>
                    <br />

                    <ul>
                      <li>{hotel.propertyPolicies}</li>
                    </ul>

                    <br />

                    <br />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="tab-right-side">
          <div className="Collection-detail">
            <h3>{hotel.propertyName}</h3>

            <p>
              <i className="bi bi-geo-alt-fill"></i>
              {hotel.address}
            </p>

            {/* Aminities showing */}
            <div className="pool-size">
              <h6>
                <img src={size1} /> upto {hotel.guestCapacity} guest
              </h6>
              <h6>
                <img src={size2} />
                {hotel.totalRooms} Rooms
              </h6>
              <h6>
                <img src={size} />
                {hotel.poolSize.length} X {hotel.poolSize.breadth} Pool Size
              </h6>
            </div>
            <p>Price</p>
            <h2>₹ {hotel.rates[0].weekend} / Night</h2>

            <div className="btn-col">
              <button>
                <a
                  style={{
                    textDecoration: "none",
                    color: "#FB7712",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center"
                  }}
                  // href={`tel:+91${hotel?.customerConnect}`}
                  // onClick={() => {
                  //   setModal(true);
                  //   setSelectedMod("call");
                  // }}
                  onClick={() => handleToogleModel(true, "call")}>
                  <i class="bi bi-telephone-fill"></i>Call
                </a>
              </button>

              <button onClick={() => setTicketModel(true)}>
                <i class="bi bi-sticky-fill"></i> Ticket
              </button>
            </div>
            <button
              onClick={() => handleToogleModel(true, "whatsapp")}
              // onClick={() => {
              //   setModal(true);
              //   setSelectedMod("whatsapp");
              // }}
              className="whatsapp-btn">
              <i class="bi bi-whatsapp"></i>Chat Via Whatsapp
            </button>
          </div>
        </div>
      </div>

      <Footer />

      <Modal size="md" toggle={() => setModal(!modal)} isOpen={modal}>
        <ModalHeader toggle={() => setModal(!modal)}>Book Now</ModalHeader>
        <ModalBody>
          <div className="modal-div">
            {/* <label>Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Your Name"
            />
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Your Email"
            />
            <label>Contact</label>
            <input
              type="text"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              placeholder="Enter Your Contact Number"
            /> */}
            <label>Number of People</label>
            <input
              type="number"
              value={noOfPeople}
              onChange={(e) => setNoOfPeople(e.target.value)}
              placeholder="Enter Number of People"
              min="1"
            />

            <label>Customer Type</label>
            <select
              value={customerType}
              onChange={(e) => setCustomerType(e.target.value)}>
              <option value="" disabled>
                Select Customer Type
              </option>
              {customerTypeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
              {/* <option value="bachelors">Bachelor's (Only Boys)</option>
              <option value="mixedGroup">Mixed Group</option>
              <option value="couples">Couples</option>
              <option value="corporate">Corporate</option>
              <option value="family">Family</option> */}
            </select>

            <label>Start Date:</label>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              minDate={new Date()}
              placeholderText="Select Start Date"
            />
            <label>End Date:</label>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="Select End Date"
            />
            <br />
            <button onClick={handleSubmit}>Book Now</button>
          </div>
        </ModalBody>
      </Modal>

      {/* <Modal size="lg" toggle={() => setModal2(!modal2)} isOpen={modal2}>
        <ModalHeader toggle={() => setModal2(!modal2)}>Ameneties</ModalHeader>
        <ModalBody>
          <div className="Ameneties-div">
            {hotel.amenities &&
              hotel.amenities.map((amenities, index) => (
                <div key={index} className="Ameneties">
                  <h3>
                    <img src={amenities.image} />
                    {amenities.amenityName}
                  </h3>
                </div>
              ))}
          </div>
        </ModalBody>
      </Modal> */}
      <Modal size="lg" toggle={() => setModal2(!modal2)} isOpen={modal2}>
        <ModalHeader toggle={() => setModal2(!modal2)}>Ameneties</ModalHeader>
        <ModalBody>
          <div>
            {Object.keys(groupedAmenities).map((categoryName, index) => (
              <div key={index} className="Ameneties-category">
                <h3 className="category-title">{categoryName}</h3>
                <div className="Ameneties-div">
                  {groupedAmenities[categoryName].map((amenity, index) => (
                    <div key={index} className="Ameneties">
                      <h3>
                        <img src={amenity.image} />
                        {amenity.amenityName}
                      </h3>
                      {/* <img
                        src={amenity.image}
                        alt={amenity.amenityName}
                        className="amenity-image"
                      />
                      <span>{amenity.amenityName}</span> */}
                    </div>
                  ))}
                </div>
                  <br />
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>
      <Modal size="lg" toggle={() => setModal3(!modal3)} isOpen={modal3}>
        <ModalHeader toggle={() => setModal3(!modal3)}>
          {hotel.propertyName}
        </ModalHeader>
        <ModalBody>
          <div className="allimg">
            {hotel.images.other.map((image, index) => (
              <img src={image} alt={`Slide ${index + 1}`} />
            ))}
          </div>
        </ModalBody>
      </Modal>

      <div className="whatsaps">
        <img src={whats} onClick={() => setTicketModel(true)} />
        <a
          // href={`tel:+91${hotel?.customerConnect}`}
          onClick={() => handleToogleModel(true, "call")}>
          <img src={whats2} />
        </a>
        <img src={whats3} onClick={() => handleToogleModel(true, "whatsapp")} />
      </div>

      {/*----------------- ticket model caling her. ---------------*/}
      <Ticket
        ticketModel={ticketModel}
        toggleModel={(val) => setTicketModel(val)}
      />

      {/*----------------- ticket model caling her. ---------------*/}
    </>
  );
}

export default CollectionDetail;

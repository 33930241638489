import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import size from "../Assets/Screenshot_2024-11-28_123540-removebg-preview.png";
import size1 from "../Assets/person.png";
import size2 from "../Assets/room.png";

function PropertyCard() {
  const [hotels, setHotels] = useState([]);
  const navigate = useNavigate();

  // console.log(hotels);
  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const response = await axios.get(
          "https://tripolo-backend.vercel.app/api/selected-property/valid"
        );
        if (response.status == 200) {
          setHotels(response?.data?.data?.hotels);
        }
      } catch (error) {
        console.error("Error fetching hotels:", error);
      }
    };
    fetchHotels();
  }, []);

  const handleHotelClick = (id) => {
    navigate(`/collectiondetail/${id}`);
  };

  return (
    <div className="trending-cards">
      {hotels.map((hotel) => (
        <div>
          <div
            key={hotel._id}
            className="trending-card trending-card010"
            onClick={() => handleHotelClick(hotel._id)}>
            <img
              src={hotel.images.profile}
              alt={hotel.propertyName}
              className="hotel-image"
            />
            <div className="trending-text">
              <h3>{hotel.propertyName}</h3>

              <div className="trending-text-line">
                <p>
                  <i className="bi bi-geo-alt-fill"></i> {hotel.city},{" "}
                  {hotel.state}
                </p>
              </div>
              <div className="trending-text-line2">
                <p>
                  <img src={size1} /> {hotel.guestCapacity}
                </p>
                <p>
                  <img src={size2} /> {hotel.totalRooms}
                </p>

                <p>
                  <img src={size} /> {hotel.poolSize.length} X{" "}
                  {hotel.poolSize.breadth}
                </p>
              </div>

              <div className="trending-text-line">
                {hotel.rates.length > 0 && (
                  <h2>
                    ₹ {hotel.rates[0].weekend} <span>/ night</span>
                  </h2>
                )}
                <button>Book Now</button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PropertyCard;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import AllCollections from "./Pages/AllCollections";
import CollectionDetail from "./Pages/CollectionDetail";
import Contact from "./Pages/Contact";
import TermanCondition from "./Pages/TermanCondition";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Collections from "./Pages/Collections";
import VenderLogin from "./Pages/VenderLogin";
import Dashboard from "./Pages/Dashboard";
import 'bootstrap/dist/css/bootstrap.css'
import Signup from "./Pages/Signup";
import LocationCollections from "./Pages/LocationCollections";
import GetbyID from "./Pages/GetbyID";
import Hotel from "./Pages/Hotel";


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/allcollections" element={<AllCollections/>} /> */}
          <Route path="/collectiondetail/:id" element={<CollectionDetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/termancondition" element={<TermanCondition />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/collections" element={<Collections />} />
          <Route path="/login" element={<VenderLogin />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/signup" element={<Signup />} />

          <Route path="/collections/:locationId" element={<LocationCollections />} />
          <Route path="/collection-details/:collectionId" element={<Hotel />} />

          <Route path="/search-results/location/:locationid/collection/:collectionid" element={<AllCollections />} />
          <Route path="/search-results/collection/:collectionid" element={<AllCollections />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

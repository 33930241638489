import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import { Modal, ModalHeader, ModalBody } from "reactstrap";

function ExploreCards() {
  const [modal, setModal] = useState(false);

    const [locations, setLocations] = useState([]);
    const [showAll, setShowAll] = useState(false); // State to control modal visibility
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://tripolo-backend.vercel.app/api/location/all')
            .then(response => {
                setLocations(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching locations:', error);
            });
    }, []);

    const handleCardClick = (locationId) => {
        navigate(`/collections/${locationId}`);
    };

    const handleSeeAllClick = () => {
        setShowAll(true); // Show modal
    };

    const closeModal = () => {
        setShowAll(false); // Close modal
    };

    return (
        <>
        <div className="ExploreTripolo-heading">
            <h2 className="ExploreTripolo">Explore Tripolo</h2>
            <button 
                className="seeall-btn" 
                style={{ textDecoration: "none", color: "white" }}
                onClick={() => setModal(true)}
            >
                See All
            </button>
        </div>
        <br />
        <br />
        <div className='Explore-cards'>
            {(showAll ? locations : locations.slice(0, 8)).map((location) => (
                <div
                    className='Explore-card-div'
                    key={location._id}
                    onClick={() => handleCardClick(location._id)}
                >
                    <div className='Explore-card'>
                        <img
                            src={location.profilePicture || 'default_image_url_here'}
                            alt={location.city}
                        />
                    </div>
                    <p>{location.city}</p>
                </div>
            ))}
        </div>
        <Modal size="lg" toggle={() => setModal(!modal)} isOpen={modal}>
        <ModalHeader toggle={() => setModal(!modal)}>Explore Tripolo</ModalHeader>
        <ModalBody>
        <div className='Explore-cards2'>
            {locations.map((location) => (
                <div
                    className='Explore-card-div'
                    key={location._id}
                    onClick={() => handleCardClick(location._id)} 
                >
                    <div className='Explore-card'>
                        <img
                            src={location.profilePicture || 'default_image_url_here'}
                            alt={location.city}
                        />
                    </div>
                    <p>{location.city}</p>
                </div>
            ))}
        </div>
        </ModalBody>
      </Modal>
       
    </>

    );
}

export default ExploreCards;

import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

function SliderBanner() {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    // Fetch the banners from the API
    fetch('https://tripolo-backend.vercel.app/api/banners/all')
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200) {
          setBanners(data.data); // Set the banners state with the fetched data
        } else {
          console.error('Failed to fetch banners');
        }
      })
      .catch((error) => {
        console.error('Error fetching banners:', error);
      });
  }, []);
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        modules={[Autoplay]}
        className="mySwiper"
      >
       {banners.map((banner) => (
        <SwiperSlide
        key={banner._id}
            style={{
              backgroundImage: `url(${banner.imageUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              
            }}>    
         <div className='banner'>
      <h2>{banner.title}</h2> 
      {banner.description && <p>{banner.description}</p>}<p>{banner.description}</p>
     
 
     
      {banner.link && (
  <a 
    href={banner.link.startsWith('http') ? banner.link : `https://${banner.link}`} 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <button className="book-now-button">Book Now</button>
  </a>
)}
      </div>
      </SwiperSlide>
    ))}
      {/* <SwiperSlide>    
         <div className='banner'>
      <h2>Find Next PlaceTo Visit</h2>
      
      <p>Discover amzaing places at exclusive deals.Eat, Shop, Visit
      interesting places around the world.</p>
      <br/>
    
      <button>Book Now</button>
      </div>
      </SwiperSlide>
      <SwiperSlide>    
         <div className='banner'>
      <h2>Find Next PlaceTo Visit</h2>
      
      <p>Discover amzaing places at exclusive deals.Eat, Shop, Visit
      interesting places around the world.</p>
      <br/>
    
      <button>Book Now</button>
      </div>
      </SwiperSlide>
      <SwiperSlide>    
         <div className='banner'>
      <h2>Find Next PlaceTo Visit</h2>
      
      <p>Discover amzaing places at exclusive deals.Eat, Shop, Visit
      interesting places around the world.</p>
      <br/>
    
      <button>Book Now</button>
      </div>
      </SwiperSlide>
      <SwiperSlide>    
         <div className='banner'>
      <h2>Find Next PlaceTo Visit</h2>
      
      <p>Discover amzaing places at exclusive deals.Eat, Shop, Visit
      interesting places around the world.</p>
      <br/>
    
      <button>Book Now</button>
      </div>
      </SwiperSlide> */}
        
      </Swiper>
    </>
  )
}

export default SliderBanner
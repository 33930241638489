import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import login from '../Assets/rb_2413.png';

function VenderLogin() {
  // State to manage User ID, Password, and API response
  const [userID, setUserID] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Use the useNavigate hook for redirection
  const navigate = useNavigate();

  // Function to handle login
  const handleLogin = async () => {
    try {
      // Call the API with User ID and Password
      const response = await axios.post('https://tripolo-backend.vercel.app/api/user/login', {
        email: userID,
        password: password,
      });

      // If login is successful, handle the response
      if (response.status === 200) {
        const { user, token } = response.data.data; // Destructure the response correctly

        setSuccessMessage('Logged in successfully!');
        setErrorMessage(''); // Clear any previous errors

        // Store user data and token in localStorage
        localStorage.setItem('user', JSON.stringify(user));  // Store user data
        localStorage.setItem('token', token);  // Store token

        // Redirect to home page or dashboard
        navigate('/');
      }
    } catch (error) {
      // Handle error if login fails
      setErrorMessage('Invalid User ID or Password. Please try again.');
      setSuccessMessage(''); // Clear any previous success message
    }
  };

  return (
    <>
      <Navbar />
      <div className='login-page'>
        <div className='login-page-left'>
          <img src={login} alt="Login" />
        </div>
        <div className='login-page-right'>
          <div className='login-page-right-form'>
            <h3>Login</h3>
            <br />
            <br />
            <label>User ID</label>
            <input
              placeholder='Enter Your User ID'
              value={userID}
              onChange={(e) => setUserID(e.target.value)}
            />
            <br />
            <label>Password</label>
            <input
              type='password'
              placeholder='Enter Your Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <br />
            {/* Display error or success messages */}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
            
            <button onClick={handleLogin}>Login</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default VenderLogin;

import React, { useState } from 'react';
import axios from 'axios';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import login from '../Assets/rb_2413.png';

function Signup() {
  // State to manage input fields and API response
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Function to handle signup
  const handleSignup = async () => {
    try {
      // Call the API with input data
      const response = await axios.post('https://tripolo-backend.vercel.app/api/user/signup', {
        name: name,
        email: email,
        phone: phone,
        password: password,
      });

      // If signup is successful, handle the response
      if (response.status === 200) {
        setSuccessMessage('Account created successfully!');
        setErrorMessage(''); // Clear any previous errors

        // Clear input fields after successful signup
        setName('');
        setEmail('');
        setPhone('');
        setPassword('');
      }
    } catch (error) {
      // Handle error if signup fails
      setErrorMessage('Signup failed. Please try again.');
      setSuccessMessage(''); // Clear any previous success message
    }
  };

  return (
    <>
      <Navbar />
      <div className='login-page'>
        <div className='login-page-left'>
          <img src={login} alt="Signup" />
        </div>
        <div className='login-page-right'>
          <div className='login-page-right-form'>
            <h3>Signup</h3>
            <br />
            <br />
            <label>Name</label>
            <input
              placeholder='Enter Your Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <br />
            <label>Email</label>
            <input
              placeholder='Enter Your Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <label>Phone</label>
            <input
              placeholder='Enter Your Mobile No.'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <br />
            <label>Password</label>
            <input
              type='password'
              placeholder='Enter Your Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <br />
            {/* Display error or success messages */}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
            
            <button onClick={handleSignup}>Create a New Account</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Signup;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SelectedCol() {
  const navigate = useNavigate();
  const [hotels, setHotels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://tripolo-backend.vercel.app/api/selected-collection/valid"
        );
        const data = await response.json();

        if (data?.data) {
          // Sort the hotels array by collectionName or collectionId
          const sortedHotels = data.data.sort((a, b) =>
            a.collectionName.localeCompare(b.collectionName) // Sort alphabetically by name
          );
          setHotels(sortedHotels);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="coll2">
      {hotels.map((hotel) => (
        <div
          key={hotel?.collectionId}
          className="col-card "
          style={{
            backgroundImage: `url(${hotel.image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={() => navigate(`/search-results/collection/${hotel?.collectionId}`)}
        >
          <div className="overlay overlay2">
            <p>{hotel.collectionName}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SelectedCol;

import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Link } from 'react-router-dom'

function TermanCondition() {
  return (
    <>
        <Navbar/>
        <div className='banner banner2'>
      <h2>Understand the rules and guidelines for using our services.</h2>
     
      </div>
      <div className='explore explore tnc'>
      <h2 style={{textAlign:'center'}}>Terms and Conditions</h2>
      <br/>
      <br/>
        
    <h3>1. Acceptance of Terms</h3>
    <p>
        By using our website and services, you agree to comply with these Terms and Conditions. If you do not agree, please refrain from using our services.
    </p>

    <h3>2. Use of Services</h3>
    <p>
        You agree to use our services responsibly and only for lawful purposes. Misuse, including fraudulent activities, is strictly prohibited.
    </p>

    <h3>3. Privacy</h3>
    <p>
        Your privacy is important to us. Please refer to our <Link style={{textDecoration:'none' , color:'#fb7712'}} to='/privacypolicy'>Privacy Policy</Link> for details on how we handle your personal data.
    </p>

    <h3>4. Intellectual Property</h3>
    <p>
        All content on our website, including text, images, logos, and designs, is protected by copyright and intellectual property laws. Unauthorized use is prohibited.
    </p>

    <h3>5. Limitation of Liability</h3>
    <p>
        We are not liable for any direct, indirect, or incidental damages arising from your use of our services. Use at your own risk.
    </p>

    <h3>6. Changes to Terms</h3>
    <p>
        We reserve the right to modify these Terms and Conditions at any time. Changes will be effective upon posting on our website.
    </p>

    <h3>7. Contact Us</h3>
    <p>
        For questions or concerns about these Terms and Conditions, please contact us at <a style={{textDecoration:'none' , color:'#fb7712'}} href="mailto:Info@tripolostays.com">Info@tripolostays.com</a>.
    </p>
      </div>

        <Footer/>
    </>
  )
}

export default TermanCondition
import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/rb_87230.png'
function Contact() {
  return (
   <>
    <Navbar/>
    <div className='banner banner2'>
      <h2>We're here to help<br/>let's connect!</h2>
     
      </div>
      <div className='explore'>
      <div className='contact-us'>
      <div className='contact-us-left'>
      <img src={img}/>

</div>
<div className='contact-us-right'>
<div className='contact-form'>
<h2>let's connect!</h2>
<br/>
<br/>
<label>Name</label>
<br/>
<input placeholder='Enter Name' />
<br/>
<br/>

<label>Mobile No.</label>
<br/>
<input placeholder='Enter Mobile No.' />
<br/>
<br/>

<label>Email</label>
<br/>
<input placeholder='Enter Email' />
<br/>
<br/>

<label>Message</label>
<br/>
<textarea placeholder='Enter Message' />
<br/>
<br/>
<button>Submit</button>
</div>

</div>
      </div>
      </div>
    <Footer/>
   </>
  )
}

export default Contact
import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";

function Ticket({ ticketModel, toggleModel }) {
  // State management
  const [ticketType, setTicketType] = useState("");
  const [issue, setIssue] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false); // Loader state

  // Options for the dropdowns
  const options = {
    "Problem regarding property Owner": [
      "No response on inquiries/call",
      "Incorrect contact number of owner",
      "Owner not agreeing to the listed price",
      "Availability issue",
      "Others"
    ],
    "Problems regarding property": [
      "Misleading/fake photos",
      "Property details not as listed",
      "Issues regarding services [ meals, pool, hygiene issues ]",
      "Inappropriate behavior from owner/staff",
      "Services not up to mark",
      "Others"
    ],
    "Other Issues": ["Other Issues (Please specify)"]
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Token not found. Please login.");
      return;
    }

    if (!ticketType || !issue || !description) {
      alert("Please fill out all fields before submitting the ticket.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        "https://tripolo-backend.vercel.app/api/ticket-user/add",
        {
          ticketType,
          issue,
          description
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.status === 200) {
        alert("Ticket Submitted Successfully.");
        toggleModel(false); // Close the modal after submission
      }
    } catch (error) {
      console.error("Error submitting ticket:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    return () => {
      setTicketType("");
      setIssue("");
      setDescription("");
    };
  }, []);

  return (
    <Modal
      size="md"
      toggle={() => toggleModel(!ticketModel)}
      isOpen={ticketModel}>
      <ModalHeader toggle={() => toggleModel(!ticketModel)}>
        Submit Ticket
      </ModalHeader>
      <ModalBody>
        {/* {loading && <div className="loader">Submitting...</div>} */}
        <div className="modal-div">
          {/* Ticket Type Dropdown */}
          <label htmlFor="ticketType">Select Issue Type</label>
          <select
            id="ticketType"
            value={ticketType}
            onChange={(e) => {
              setTicketType(e.target.value);
              setIssue(""); // Reset issue when ticket type changes
            }}>
            <option value="">-- Select Issue Type --</option>
            {Object.keys(options).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>

          {/* Issue Dropdown */}
          {ticketType && (
            <>
              <label htmlFor="issue">Select Specific Issue</label>
              <select
                id="issue"
                value={issue}
                onChange={(e) => setIssue(e.target.value)}>
                <option value="">-- Select Specific Issue --</option>
                {options[ticketType].map((opt) => (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                ))}
              </select>
            </>
          )}

          {/* Description Input */}
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            placeholder="Enter a brief description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows="4"
          />
          <br />
          {/* Submit Button */}
          <button onClick={handleSubmit} disabled={loading}>{loading ?"Submitting ....":"Submit Ticket"}</button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default Ticket;
